import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Navigate, Redirect } from 'react-router-dom';
import apiClient from '../apiClient';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PasswordIcon from '@mui/icons-material/Password';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';

import logo from '../assets/logos/7.1.png';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://dev.bpdteam.com">
        BPDev
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [values, setValues] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    password_confirmation: '',
    showPassword: false,
    type: 'user'
  });
  
  function handleClick() {
    setLoading(true);

    apiClient.get('/sanctum/csrf-cookie')
      .then(response => {

        apiClient.post('/api/users', 
          values,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )
        .then((res) => {
          setError(false);
          setErrorMessage('');
          localStorage.setItem('Token', res.data.token);
          localStorage.setItem('User', JSON.stringify(res.data.user));
          setLoading(false);
          setRedirect(true);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setErrorMessage(JSON.stringify(e.response.data.errors.email[0]).replace(/"/, '').replace(/"/, ''));
          setLoading(false);
        });
      })
      .catch((e) => console.log(e));
  }

  const handleClickShowPassword = (e) => {
    e.preventDefault();

    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleChangeName = (e) => {
    setValues({
      ...values,
      name: e.target.value,
    });
  }

  const handleChangeSurname = (e) => {
    setValues({
      ...values,
      surname: e.target.value,
    });
  }
  
  const handleChangeEmail = (e) => {
    setValues({
      ...values,
      email: e.target.value,
    });
  }

  const handleChangePassword = (e) => {
    setValues({
      ...values,
      password: e.target.value,
    });
  }

  const handleChangePasswordConfirmation = (e) => {
    setValues({
      ...values,
      password_confirmation: e.target.value,
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      {redirect ? <Navigate to='/'/> : null}
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="logo" width={400}/>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                value={values.name}
                onChange={(e) => handleChangeName(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="lname"
                name="surname"
                variant="outlined"
                required
                fullWidth
                id="surname"
                label="Surname"
                autoFocus
                value={values.surname}
                onChange={(e) => handleChangeSurname(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={values.email}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => handleChangeEmail(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={values.password}
                type={values.showPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => handleChangePassword(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => handleClickShowPassword(e)}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={values.password_confirmation}
                type={values.showPassword ? 'text' : 'password'}
                name="password"
                label="Confirm Password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => handleChangePasswordConfirmation(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => handleClickShowPassword(e)}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button> */}
          <LoadingButton
            onClick={() => handleClick()}
            loading={loading}
            loadingIndicator="Loading..."
            variant="contained"
            fullWidth
            color="primary"
            className={classes.submit}
            style={{marginTop: 10}}
          >
            Sign Up
          </LoadingButton>
          <Grid container justify="flex-end" style={{marginTop: 10}}>
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}