import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PeopleIcon from '@material-ui/icons/People';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import IsoIcon from '@mui/icons-material/Iso';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import HailIcon from '@mui/icons-material/Hail';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import UpdateIcon from '@material-ui/icons/Update';
import SettingsIcon from '@mui/icons-material/Settings';
import PinDropIcon from '@material-ui/icons/PinDrop';
import CategoryIcon from '@mui/icons-material/Category';
import WcIcon from '@material-ui/icons/Wc';
import MoneyIcon from '@material-ui/icons/Money';
import EmailIcon from '@material-ui/icons/Email';
import TableChartIcon from '@material-ui/icons/TableChart';
import LockIcon from '@material-ui/icons/Lock';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PaymentsIcon from '@mui/icons-material/Payments';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import GavelIcon from '@material-ui/icons/Gavel';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Link } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import GroupIcon from '@mui/icons-material/Group';
import { CalendarToday } from '@material-ui/icons';
import { Call, ContactEmergency, EmergencyShare } from '@mui/icons-material';


export const AdminMainListItems = () => {

  const [openTalent, setOpenTalent] = useState(false);
  const [openAccounts, setOpenAccounts] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false);
  const [openReports, setOpenReports] = useState(false);

  const handleClickReports = () => {
    setOpenReports(!openReports);
  };
  const handleClickTalent = () => {
    setOpenTalent(!openTalent);
  };

  const handleClickAccounts = () => {
    setOpenAccounts(!openAccounts);
  };

  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleClickSuppliers = () => {
    setOpenSuppliers(!openSuppliers);
  };

 

  return(
    <div>
      <Link to="/" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Link>
      <Link to="/staff" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Staff" />
        </ListItem>
      </Link>
      <Link to="/calendar" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText primary="Calendar" />
        </ListItem>
      </Link>
      <Link to="/emergency" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <Call />
          </ListItemIcon>
          <ListItemText primary="Emergency Contacts" />
        </ListItem>
      </Link>
    </div>
  );
};

