import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
import Table from '../../elements/Table';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Calendar() {
    const classes = useStyles();
  
    var roles = {};

    useEffect(() => {
      // apiClient.get(
      //   `/api/roles/`,
      //   {
      //     headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //   })
      //   .then((res) => {
      //     res.data.map((item) => {
      //       roles[item.id] = item.name;
      //       return null;
      //     });
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      }, [])
  
    const columns = [
        { title: 'ID', field: 'id' },
        { title: 'Name', field: 'name' },
        { title: 'Description', field: 'description' },
        { title: 'Date', field: 'date' },
        { title: 'Color', field: 'color' },
        { title: 'Category', field: 'category' },  
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Calendar"
                columns={columns}
                api="/api/calendars"
                actionsColumnIndex={0}
            />
        </Container>
    )
}
  