import React, { useEffect, useState, createRef  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
import Table from '../../elements/Table';
import placeholder from '../../assets/images/placeholder.jpg';
import { Avatar, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import StaffDialog from './dialog';
import DeleteDialog from '../../elements/Dialog/delete';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Staff() {
  const tableRef = createRef();
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [operation, setOperation] = useState('Add');
    const [uuid, setUuid] = useState('');
    const [avatar, setAvatar] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [designation, setDesignation] = useState("");
    const [dob, setDob] = useState("");
    const [celebrate_birthday, setCelebrateBirthday] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [office_phone, setOfficePhone] = useState("");
    const [mobile_phone, setMobilePhone] = useState("");
    const [whatsapp_phone, setWhatsappPhone] = useState("");
    const [joined_date, setJoinedDate] = useState("");
    const [joined_date_unicef, setJoinedDateUnicef] = useState("");
    const [gender, setGender] = useState("");
    const [supervisor, setSupervisor] = useState("");
    const [bio, setBio] = useState("");
    const [type, setType] = useState("user");
  
    const handleClickSave = () => {
        setOperation('Add');
        setOpen(true);
    };

    const handleClickEdit = (rowData) => {
        setUuid(rowData.uuid);
        setOperation('Edit');
        setAvatar(rowData.avatar);
        setName(rowData.name);
        setSurname(rowData.surname);
        setDesignation(rowData.designation);
        setDob(rowData.dob);
        setCelebrateBirthday(rowData.celebrate_birthday);
        setEmail(rowData.email);
        setPassword(rowData.password);
        setOfficePhone(rowData.office_phone);
        setMobilePhone(rowData.mobile_phone);
        setWhatsappPhone(rowData.whatsapp_phone);
        setJoinedDate(rowData.joined_date);
        setJoinedDateUnicef(rowData.joined_date_unicef);
        setGender(rowData.gender);
        setSupervisor(rowData.supervisor);
        setBio(rowData.bio);
        setType(rowData.type);
        setOpen(true);
    };

    const handleClickDelete = (rowData) => {
        setOperation('Delete');
        setUuid(rowData.uuid);
        setName(rowData.name);
        setSurname(rowData.surname);
        setOpenDelete(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDelete = () => {
      setOpenDelete(false);
    };

    const handleSave = () => {
        let newData = {
            avatar: avatar,
            name: name,
            surname: surname,
            designation: designation,
            dob: dob,
            celebrate_birthday: celebrate_birthday,
            email: email,
            password: password,
            password_confirmation: password,
            office_phone: office_phone,
            mobile_phone: mobile_phone,
            whatsapp_phone: whatsapp_phone,
            joined_date: joined_date,
            joined_date_unicef: joined_date_unicef,
            gender: gender,
            supervisor: supervisor,
            bio: bio,
            type: type
        };
        if (operation === 'Add') {
            apiClient
                .post('/api/users', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    // tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                    handleCloseDelete();
                })
                .catch((e) => {});
        } else if (operation === 'Edit') {
            apiClient
                .put('/api/users/' + uuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    // tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                    handleCloseDelete();
                })
                .catch((e) => {});
        } else if (operation === 'Delete') {
            apiClient
                .delete('/api/users/' + uuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    // tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                    handleCloseDelete();
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };


    useEffect(() => {
      apiClient.get(
        `/api/users`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          setUsers(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
      }, [])
  
    const columns = [
        { title: 'ID', field: 'id', editable: 'never' },
        {
          title: "Avatar",
          field: "avatar",
          render: (rowData) => <img src={rowData.avatar ? rowData.avatar : placeholder} style={{ width: 40, height: 40, borderRadius: "50%" }} />,
          editComponent: props => (
            <>
              <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <Avatar src={props.value} className={classes.large} />
                </IconButton>
              </label>
            </>
          )
        },
        { title: 'Name', field: 'name' },
        { title: 'Surname', field: 'surname' },
        { title: 'Designation', field: 'designation' },
        { title: 'Date of Birth', field: 'dob' },
        { 
          title: 'Celebrate Birthday', 
          field: 'celebrate_birthday',
        },
        { title: 'Email', field: 'email' },
        { title: 'Password', field: 'password' },
        { title: 'Office Number', field: 'office_phone' },
        { title: 'Mobile Number', field: 'mobile_phone' },
        { title: 'WhatsApp Number', field: 'whatsapp_phone' },
        { title: 'Date Joined UNICEF', field: 'joined_date' },
        { title: 'Date Joined UNICEF Eswatini', field: 'joined_date_unicef' },
        { 
          title: 'Gender', 
          field: 'gender', 
          lookup: { 0: 'Male', 1: 'Female' }
        },
        { title: 'Supervisor', field: 'supervisor' }, 
        { title: 'Bio', field: 'bio' }, 
        { title: 'Type', field: 'type' },   
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Staff"
                columns={columns}
                api="/api/users"
                data={users}
                actionsColumnIndex={0}
                customActions={true}
                filtering={true}
                tableRef={tableRef}
                actions={[
                  {
                      icon: AddBox,
                      tooltip: 'Add Staff Member',
                      isFreeAction: true,
                      onClick: (event) => handleClickSave()
                  },
                  {
                      icon: Edit,
                      tooltip: 'Edit Staff Member',
                      onClick: (event, rowData) => {
                        handleClickEdit(rowData);
                      }
                  },
                  {
                      icon: DeleteOutline,
                      tooltip: 'Delete Staff Member',
                      onClick: (event, rowData) => {
                        handleClickDelete(rowData);
                      }
                  }
              ]}

            />

            <StaffDialog
              open={open}
              handleClose={handleClose}
              avatar={avatar}
              setAvatar={setAvatar}
              name={name}
              setName={setName}
              surname={surname}
              setSurname={setSurname}
              designation={designation}
              setDesignation={setDesignation}
              dob={dob}
              setDob={setDob}
              celebrate_birthday={celebrate_birthday}
              setCelebrateBirthday={setCelebrateBirthday}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              office_phone={office_phone}
              setOfficePhone={setOfficePhone}
              mobile_phone={mobile_phone}
              setMobilePhone={setMobilePhone}
              whatsapp_phone={whatsapp_phone}
              setWhatsappPhone={setWhatsappPhone}
              joined_date={joined_date}
              setJoinedDate={setJoinedDate}
              joined_date_unicef={joined_date_unicef}
              setJoinedDateUnicef={setJoinedDateUnicef}
              gender={gender}
              setGender={setGender}
              supervisor={supervisor}
              setSupervisor={setSupervisor}
              bio={bio}
              setBio={setBio}
              type={type}
              setType={setType}
              handleSave={handleSave}
            />

            <DeleteDialog
              open={openDelete}
              handleClose={handleCloseDelete}
              handleSave={handleSave}
              label={name + " " + surname}
            />

        </Container>
    )
}
  