
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

// import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/home";
import Staff from "./pages/staff";
import Calendar from "./pages/calendar";
import Emergency from "./pages/emergency";
import SignUp from "./pages/signup";
import SignIn from "./pages/signin";


function App() {
  const history = createBrowserHistory ? createBrowserHistory() : [];

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/emergency" element={<Emergency />} />
      </Routes>
    </div>
  );
}

export default App;
