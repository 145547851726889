import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from '../apiClient';
import { Navigate, Redirect } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import PasswordIcon from '@mui/icons-material/Password';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import logo from '../assets/logos/7.1.png';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Developed by '}
      <Link color="inherit" href="https://dev.bpdteam.com">
        BPDev
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  });
  
  function handleClick() {
    setLoading(true);

    apiClient.get('/sanctum/csrf-cookie')
      .then(response => {

        apiClient.post('/api/signin', 
          values,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )
        .then((res) => {
          setError(false);
          setErrorMessage('');
          localStorage.setItem('Token', res.data.token);
          localStorage.setItem('User', JSON.stringify(res.data.user));
          setLoading(false);
          setRedirect(true);
        })
        .catch((e) => {
          console.log("Error" + e);
          setError(true);
          setErrorMessage("Invalid Email/Password");
          setLoading(false);
        });
      })
      .catch((e) => console.log(e));
  }

  const handleClickShowPassword = (e) => {
    e.preventDefault();

    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleChangeEmail = (e) => {
    setValues({
      ...values,
      email: e.target.value,
    });
  }

  const handleChangePassword = (e) => {
    setValues({
      ...values,
      password: e.target.value,
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      {redirect ? <Navigate to='/'/> : null}
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="logo" width={400}/>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => handleChangeEmail(e)}
            value={values.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => handleChangePassword(e)}
            value={values.password}
            type={values.showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => handleClickShowPassword(e)}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <LoadingButton
            onClick={() => handleClick()}
            loading={loading}
            loadingIndicator="Loading..."
            variant="contained"
            fullWidth
            color="primary"
            className={classes.submit}
            style={{marginTop: 10}}
          >
            Login
          </LoadingButton>
          <Grid container style={{marginTop: 10}}>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}