import {
    Avatar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00/00"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function StaffDialog(props) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Staff</DialogTitle>
            <DialogContent>
                <DialogContentText>Fill in the form to add/edit a staff member</DialogContentText>
                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                    <>
                        <label htmlFor="icon-button-file">
                            <IconButton 
                                color="primary" 
                                aria-label="upload picture" 
                                component="span"
                                // onClick={() => alert("hi")}
                            >
                                <Avatar src={props.avatar} className={classes.large} style={{width: 150, height: 150}} />
                            </IconButton>
                        </label>
                        <input style={{display: 'none !important', alignSelf: 'center'}} accept="image/*" className={classes.input} onChange={(e) => props.setAvatar(e.target.value)} id="icon-button-file" type="file" />
                    </>
                    <Grid item xs={6}>
                        <TextField
                            value={props.name}
                            onChange={(e) => props.setName(e.target.value)}
                            margin="dense"
                            id="name"
                            label="First Name *"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.surname}
                            onChange={(e) => props.setSurname(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Surname *"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={props.designation}
                            onChange={(e) => props.setDesignation(e.target.value)}
                            margin="dense"
                            id="designation"
                            label="Designation"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField
                            value={props.dob}
                            onChange={(e) => props.setDob(e.target.value)}
                            margin="dense"
                            id="dob"
                            label="Date of Birth"
                            type="text"
                            fullWidth
                            variant="outlined"
                        /> */}
                        <FormControl fullWidth margin='dense'>
                            <InputLabel htmlFor="dob">Date of Birth</InputLabel>
                            <OutlinedInput
                                value={props.dob}
                                onChange={(e) => props.setDob(e.target.value)}
                                id="dob"
                                name="dob"
                                margin="dense"
                                inputComponent={TextMaskCustom}
                            />
                            <FormHelperText id="dob-helper-text">
                                Date Format DD/MM
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField
                            value={props.celebrate_birthday}
                            onChange={(e) => props.setCelebrateBirthday(e.target.value)}
                            margin="dense"
                            id="celebrate_birthday"
                            label="Celebrate Birthday"
                            type="text"
                            fullWidth
                            variant="outlined"
                        /> */}
                        <FormControlLabel control={
                            <Checkbox 
                                defaultChecked={true}
                                checked={props.celebrate_birthday}
                                onChange={(e) => {console.log(e);props.setCelebrateBirthday(e.target.checked);}}
                                margin="dense"
                                fullWidth
                                id="celebrate_birthday"
                            />
                            } 
                            label="Celebrate Birthday"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.email}
                            onChange={(e) => props.setEmail(e.target.value)}
                            margin="dense"
                            id="email"
                            label="Email *"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField
                            value={props.password}
                            onChange={(e) => props.setPassword(e.target.value)}
                            margin="dense"
                            id="password"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="outlined"
                        /> */}
                        <FormControl fullWidth margin='dense' variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                margin="dense"
                                fullWidth
                                value={props.password}
                                onChange={(e) => props.setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password *"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.office_phone}
                            onChange={(e) => props.setOfficePhone(e.target.value)}
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.mobile_phone}
                            onChange={(e) => props.setMobilePhone(e.target.value)}
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.whatsapp_phone}
                            onChange={(e) => props.setWhatsappPhone(e.target.value)}
                            margin="dense"
                            id="whatsapp_phone"
                            label="Whatsapp Phone"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.joined_date}
                            onChange={(e) => props.setJoinedDate(e.target.value)}
                            margin="dense"
                            id="joined_date"
                            label="Date Joined Unicef Eswatini"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.joined_date_unicef}
                            onChange={(e) => props.setJoinedDateUnicef(e.target.value)}
                            margin="dense"
                            id="joined_date_unicef"
                            label="Date Joined Unicef"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.gender}
                            onChange={(e) => props.setGender(e.target.value)}
                            margin="dense"
                            id="gender"
                            label="Gender"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.supervisor}
                            onChange={(e) => props.setSupervisor(e.target.value)}
                            margin="dense"
                            id="supervisor"
                            label="Supervisor"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.type}
                            onChange={(e) => props.setType(e.target.value)}
                            margin="dense"
                            id="type"
                            label="Type"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={props.bio}
                            onChange={(e) => props.setBio(e.target.value)}
                            margin="dense"
                            id="bio"
                            label="Bio"
                            type="text"
                            fullWidth
                            variant="outlined"
                            multiline
                            maxRows={4}
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

