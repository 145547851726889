import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../elements/Title';
import apiClient from "../../apiClient";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function DashboardExpensesRecent(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.contacts);

  useEffect(() => {
    // console.log(props.users);
    setData(props.contacts)
  },[props]);

  return (
    <React.Fragment>
      <Title>Recent Events</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><b>Date</b></TableCell>
            <TableCell><b>Event</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? 
            data.slice(0, 5).map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.event}</TableCell>
              </TableRow>
            ))
            :
            null
          }
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" to={`/calendar`}>
          See more
        </Link>
      </div>
    </React.Fragment>
  );
}