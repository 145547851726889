import axios from 'axios';

 

const apiClient = axios.create({
    // baseURL: 'http://localhost:8000',
    baseURL: 'https://unicefapi.bpdteam.com',

    withCredentials: true,

});

 

export default apiClient;